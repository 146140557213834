import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  padding-top: ${(props) => (1 / props.aspectRatio) * 100}%;
  position: relative;
  ${(props) =>
    props.$inSlider &&
    css`
      border-radius: 5px;
      overflow: hidden;
      top: ${props.chillBlueTheme ? '0' : '50%'};
      transform: ${props.chillBlueTheme ? 'none' : 'translateY(-50%)'};
    `}
`;
const StyledIframe = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0;
`;

Wrapper.propTypes = {
  aspectRatio: PropTypes.number.isRequired,
};

function YoutubeVideo({ title, url, aspectRatio, inSlider, chillBlueTheme }) {
  console.log({ title });
  return (
    <Wrapper
      $inSlider={inSlider}
      chillBlueTheme={chillBlueTheme}
      aspectRatio={aspectRatio}
    >
      <StyledIframe
        // loading="lazy"
        title={title}
        src={url}
        frameborder="0"
        allowFullScreen="allowFullScreen"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      />
    </Wrapper>
  );
}

YoutubeVideo.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  aspectRatio: PropTypes.number.isRequired,
  inSlider: PropTypes.bool,
  chillBlueTheme: PropTypes.bool,
};

YoutubeVideo.defaultProps = {
  inSlider: false,
  chillBlueTheme: false,
};

export default YoutubeVideo;
