import styled from 'styled-components';
import { Heading as BaseHeading } from '../styled';

export const BlocksWrapper = styled.div`
  margin: 0 -28px;
  display: flex;
  align-items: center;

  @media (max-width: 1200px) {
    display: block;
    margin: 0;
  }
`;

export const Block = styled.div`
  flex: 0 1 50%;
  margin: 0 28px;

  @media (max-width: 1200px) {
    margin: 32px 0;
  }
`;

export const Heading = styled(BaseHeading)`
  margin: 0 0 24px 0;
`;
