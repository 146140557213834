import React from 'react';
import YoutubeVideo from '../../YoutubeVideo';
import {
  Issue,
  SubHeading,
  EvachillAndEvalight,
  VideoAndDescription,
  Block,
  PowerIssueSolution,
  PowerIssueSolutionSubHeading,
  PowerIssueSolutionText,
  Indent,
  Ol,
} from './styled';
import { Wrapper, Heading, Text, Mark } from '../styled';

export default function Issues() {
  return (
    <Wrapper>
      <Heading>Что делать, если…</Heading>

      <Issue>
        <SubHeading>Проблемы с питанием</SubHeading>
        <EvachillAndEvalight>
          <VideoAndDescription>
            <Block>
              <YoutubeVideo
                url="https://www.youtube.com/embed/na3cqKVU1mg"
                aspectRatio={9 / 16}
              />
            </Block>
            <Block>
              <Text>
                <Mark>У evaCHILL:</Mark> устройство не работает на высоких
                скоростях вентилятора или вентилятор работает несколько секунд и
                отключается, а кнопка мигает.
              </Text>
            </Block>
          </VideoAndDescription>
          <VideoAndDescription>
            <Block>
              <YoutubeVideo
                url="https://www.youtube.com/embed/T9PrUooxaIQ"
                aspectRatio={9 / 16}
              />
            </Block>
            <Block>
              <Text>
                <Mark>У evaLIGHTplus:</Mark> слабый поток воздуха на
                максимальной скорости вентилятора и/или мигают светодиоды на
                экране, обозначающие скорость вентилятора.
              </Text>
            </Block>
          </VideoAndDescription>
        </EvachillAndEvalight>
        <Text>
          <Mark>У evaSMART:</Mark> при включении устройства происходит
          повторяющаяся перезагрузка и/или на дисплее появляется иконка молнии.
        </Text>

        <PowerIssueSolution>
          <PowerIssueSolutionSubHeading>Решение</PowerIssueSolutionSubHeading>
          <PowerIssueSolutionText>
            Вероятней всего, вашему персональному охладителю не хватает
            мощности. Моделям evaCHILL и evaLIGHTplus требуется источник питания
            не менее 2А 5В, evaSMART — не менее 2,5А 5В. Попробуйте заменить
            адаптер или подключите устройство к USB-порту компьютера/ноутбука.
            Если проблема не решилась — обратитесь в нашу&nbsp;
            <a href="#service-ru-support">службу поддержки.</a>
          </PowerIssueSolutionText>
        </PowerIssueSolution>
      </Issue>

      <Issue>
        <SubHeading>Потерялся кабель</SubHeading>
        <Text>
          Все USB-кабели в комплекте с устройствами Evapolar универсальные,
          каждый может быть заменён аналогичным от другого производителя.
          Пожалуйста, выбирайте кабель, рассчитанный на мощность не менее 12Вт.
        </Text>
      </Issue>

      <Issue>
        <SubHeading>Устройство перестало охлаждать</SubHeading>
        <Indent>
          <Text>
            Проверьте, намокает ли картридж после того, как вы наполнили
            резервуар водой.
          </Text>
        </Indent>
        <Indent>
          <Ol>
            <li>
              <Text>
                1. Если картридж намокает — вероятно, в помещении слишком
                высокая влажность воздуха и/или недостаточно жарко.
              </Text>
            </li>
            <li>
              <Text>
                2. Ещё одна возможная причина — картридж нуждается в замене (мы
                рекомендуем использовать картридж не более 6-ти месяцев в общей
                сложности).
              </Text>
            </li>
          </Ol>
        </Indent>
        <Indent>
          <Text>
            Если картридж НЕ намокает — возможно, возникла воздушная пробка в
            системе подачи воды. Обратитесь в нашу&nbsp;
            <a href="#service-ru-support">службу поддержки.</a>
          </Text>
        </Indent>
        <Indent>
          <Text>
            <Mark>Только для evaSMART:</Mark> отсоедините резервуар, наполните
            его водой, установите его обратно, а само устройство слегка (на
            10-15°) наклоните в сторону резервуара. Это даст возможность пробить
            воздушную пробку, и подача воды восстановится.
          </Text>
        </Indent>
      </Issue>
    </Wrapper>
  );
}
