import React from 'react';
import { Wrapper } from '../styled';
import { Heading, LinksWrapper, Link } from './styled';

export default function Manuals() {
  return (
    <Wrapper>
      <Heading>Инструкции на русском</Heading>
      <LinksWrapper>
        <Link href="https://s3.amazonaws.com/pg.evapolar.com/ru/RU_PRG_evaCHILL.pdf">
          evaCHILL
        </Link>
        <Link href="https://s3.amazonaws.com/pg.evapolar.com/ru/RU_PRG_evaLIGHTplus.pdf">
          evaLIGHTplus
        </Link>
        <Link href="https://s3.amazonaws.com/pg.evapolar.com/ru/RU_PRG_evaSMART.pdf">
          evaSMART
        </Link>
      </LinksWrapper>
    </Wrapper>
  );
}
