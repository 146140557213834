import React from 'react';
import { Wrapper, Heading, Text, Mark } from '../styled';
import { Indent, Ol } from './styled';

export default function Support() {
  return (
    <Wrapper id="service-ru-support">
      <Heading>Обращение в техподдержку</Heading>
      <Indent>
        <Text>
          Если у вас есть основания полагать, что ваше устройство Evapolar
          работает некорректно, или же вы обнаружили явный дефект в процессе
          эксплуатации — мы с радостью проконсультируем вас удаленно и при
          необходимости оперативно заменим устройство на новое.
        </Text>
      </Indent>

      <Indent>
        <Text>
          Для этого пришлите следующую информацию на электронную почту&nbsp;
          <a href="mailto:mail@evapolar.com">mail@evapolar.com:</a>
        </Text>
      </Indent>

      <Indent>
        <Ol>
          <li>
            <Text>
              1. Фото стикера с серийным номером (находится на дне устройства —
              <Mark>
                &nbsp;пожалуйста, не переворачивайте ваш Evapolar, если внутри
                ещё осталась вода!
              </Mark>
              );
            </Text>
          </li>
          <li>
            <Text>2. Фото/видео, демонстрирующее дефект;</Text>
          </li>
          <li>
            <Text>
              3. Информация о покупке (чек из магазина или скриншот из личного
              кабинета, если покупка была совершена онлайн).
            </Text>
          </li>
        </Ol>
      </Indent>

      <Indent>
        <Text>
          Предоставленная информация поможет нам максимально быстро разобраться
          в вашей проблеме и предложить самое оптимальное решение.
        </Text>
      </Indent>
    </Wrapper>
  );
}
