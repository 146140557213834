import styled from 'styled-components';

export const Indent = styled.div`
  margin: 24px 0;
`;

export const Ol = styled.ol`
  padding: 0;
  list-style: none;
`;
