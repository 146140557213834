import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Wrapper, Text } from './styled';

const IMAGE_PROPS = {
  loading: 'lazy',
  alt: 'eva conditioner',
  layout: 'constrained',
};

export default function Hero() {
  return (
    <Wrapper>
      <StaticImage
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...IMAGE_PROPS}
        className="image1"
        src="../../../images/service-ru/hero-1440.jpg"
      />
      <StaticImage
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...IMAGE_PROPS}
        className="image2"
        src="../../../images/service-ru/hero-768.jpg"
      />
      <StaticImage
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...IMAGE_PROPS}
        className="image3"
        src="../../../images/service-ru/hero-375.jpg"
      />
      <Text>Центр поддержки клиентов</Text>
    </Wrapper>
  );
}
