import React from 'react';
import NavBar from '../components/Navbar';
import Footer from '../components/Footer';
import Hero from '../components/ServiceRu/Hero';
import FAQ from '../components/ServiceRu/FAQ';
import Issues from '../components/ServiceRu/Issues';
import Contacts from '../components/ServiceRu/Contacts';
import Support from '../components/ServiceRu/Support';
import Manuals from '../components/ServiceRu/Manuals';

export default function ServiceRu() {
  return (
    <>
      <NavBar />
      <Hero />
      <FAQ />
      <Issues />
      <Contacts />
      <Support />
      <Manuals />
      <Footer />
    </>
  );
}
