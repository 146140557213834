import React from 'react';
import { Wrapper, Text } from '../styled';
import { BlocksWrapper, Block, Heading } from './styled';

// TODO Map layout is generated via https://yandex.ru/dev/maps/mapstools/
// Use https://react-yandex-maps.vercel.app/ instead?
function Map() {
  return (
    <div style={{ position: 'relative', overflow: 'hidden' }}>
      <a
        href="https://yandex.ru/maps/2/saint-petersburg/?utm_medium=mapframe&utm_source=maps"
        style={{
          color: '#eee',
          fontSize: '12px',
          position: 'absolute',
          top: '0px',
        }}
      >
        Санкт‑Петербург
      </a>
      <a
        href="https://yandex.ru/maps/2/saint-petersburg/?ll=30.358964%2C59.918930&mode=whatshere&utm_medium=mapframe&utm_source=maps&whatshere%5Bpoint%5D=30.358478%2C59.918981&whatshere%5Bzoom%5D=20&z=20"
        style={{
          color: '#eee',
          fontSize: '12px',
          position: 'absolute',
          top: '14px',
        }}
      >
        Улица Черняховского, 39 — Яндекс Карты
      </a>
      <iframe
        title="yandex-maps"
        src="https://yandex.ru/map-widget/v1/-/CCUB4IQJgA"
        frameBorder="0"
        allowFullScreen
        style={{
          position: 'relative',
          border: '0',
          width: '100%',
          minHeight: '400px',
        }}
      />
    </div>
  );
}

export default function Contacts() {
  return (
    <Wrapper>
      <BlocksWrapper>
        <Block>
          <Heading>Контакты сервис-центра</Heading>
          <Text>
            Если вы проживаете в Санкт-Петербурге, в случае неполадок с вашим
            устройством Evapolar вы можете обратиться в сервисный центр, который
            находится по адресу ул. Черняховского, д.39. Пожалуйста, согласуйте
            время визита заранее по телефону&nbsp;
            <a href="tel:+78125653442">+7 (812) 565-34-42</a>, или по
            электронной почте&nbsp;
            <a href="mailto:mail@evapolar.com">mail@evapolar.com</a> с темой
            “Сервисный центр”.
          </Text>
        </Block>
        <Block>
          <Map />
        </Block>
      </BlocksWrapper>
    </Wrapper>
  );
}
