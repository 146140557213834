import styled from 'styled-components';
import { colors } from '../../ui/common';

export const Wrapper = styled.section`
  font-family: 'Montserrat', sans-serif;
  max-width: 1110px;
  margin: 160px auto;
  padding: 0 100px;

  @media (max-width: 768px) {
    max-width: 568px;
    padding: 0 16px;
  }
`;

export const Heading = styled.h2`
  font-weight: 600;
  font-size: 38px;
  line-height: 150%;
  color: ${colors.color05};
  margin: 24px 0;
`;

export const Text = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  color: ${colors.color01};
  margin: 0;

  & a {
    display: inline-block;
    color: #1967ff;
    text-decoration: underline;
  }
`;

export const Mark = styled.span`
  color: ${colors.color05};
  font-weight: 600;
`;
