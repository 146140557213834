import styled from 'styled-components';

import { Text } from '../styled';

export const Issue = styled.section`
  margin: 0 0 72px 0;

  @media (max-width: 375px) {
    margin-bottom: 100px;
  }
`;

export const SubHeading = styled.h3`
  font-weight: 600;
  font-size: 32px;
  line-height: 150%;
  color: #363636;
  margin: 24px 0;
`;

export const EvachillAndEvalight = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 620px) {
    display: block;
  }
`;

export const VideoAndDescription = styled.div`
  display: flex;
  flex: 1;
  margin: 16px -16px;

  &:nth-of-type(1) {
    margin-right: auto;
  }

  @media (max-width: 620px) {
    display: block;
    margin: 42px 0;
  }
`;

export const Block = styled.div`
  margin: 0 16px;
  flex: 0 0 256px;
  min-width: 256px;
  justify-content: center;
  align-self: center;

  @media (max-width: 620px) {
    margin: 16px 0;
  }
`;

export const PowerIssueSolution = styled.section`
  display: flex;
  margin: 40px -16px 0;

  @media (max-width: 768px) {
    display: block;
  }
`;
export const PowerIssueSolutionSubHeading = styled(SubHeading)`
  margin: 0 16px;

  @media (max-width: 768px) {
    margin-bottom: 32px;
  }
`;
export const PowerIssueSolutionText = styled(Text)`
  margin: 0 16px;
`;

export const Indent = styled.div`
  margin-bottom: 32px;
`;

export const Ol = styled.ol`
  padding: 0;
  list-style: none;
`;
