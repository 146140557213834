import styled from 'styled-components';

export const Wrapper = styled.section`
  position: relative;
  max-width: 1440px;
  margin: 0 auto;

  & .image1 {
    @media (max-width: 768px) {
      display: none;
    }
  }
  & .image2 {
    display: none;

    @media (max-width: 768px) {
      display: block;
    }

    @media (max-width: 375px) {
      display: none;
    }
  }
  & .image3 {
    display: none;

    @media (max-width: 375px) {
      display: block;
    }
  }
`;

export const Text = styled.h1`
  margin: 0 32px;
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 9.24%;
  transform: translateY(-50%);
  max-width: 570px;
  height: fit-content;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 52px;
  line-height: 122.9%;
  color: #ffffff;

  @media (max-width: 768px) {
    font-size: 48px;
  }

  @media (max-width: 375px) {
    font-size: 32px;
    left: 0;
    margin: 0 16px;
  }
`;
