import styled from 'styled-components';
import { Heading as BaseHeading } from '../styled';
import { colors } from '../../../ui/common';

export const Heading = styled(BaseHeading)`
  text-align: center;
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 -5px;

  @media (max-width: 640px) {
    flex-direction: column;
  }
`;

export const Link = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6px 5px;
  border: 1px solid ${colors.color05};
  border-radius: 5px;
  width: 180px;
  height: 60px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  box-sizing: border-box;

  &:hover,
  &:focus {
    background: ${colors.color05};
    color: #fff;
  }
`;
