import React from 'react';
import FAQList from '../../FAQ/List';
import { Wrapper } from './styled';

export default function FAQ() {
  return (
    <Wrapper>
      <FAQList
        questions={[
          {
            title: [
              'Помещение какой площади способно охладить устройство Evapolar?',
              '\n',
              'Насколько значительной будет разница температур?',
            ].join(''),
            content: [
              'Устройства Evapolar являются персональными охладителями воздуха. Соответственно, они охлаждают не целое помещение, а только самого пользователя. Для того, чтобы почувствовать максимальный эффект, мы рекомендуем находиться непосредственно напротив устройства в радиусе 1-1.5 метра. Направление воздушного потока можно настроить, отрегулировав решётки.',
              '\n',
              '\n',
              'При оптимальных условиях устройство Evapolar способно охладить исходящий воздух на 10-12°C.*',
              '\n',
              '\n',
              '*по направлению воздушного потока',
            ],
          },
          {
            title: 'Могу ли я регулировать температуру исходящего воздуха?',
            content: [
              'Поскольку охлаждение воздуха происходит за счёт естественной реакции испарения воды, установить конкретную температуру невозможно.',
              '\n',
              '\n',
              'Степень охлаждения исходящего воздуха будет зависеть от температуры воздуха и относительной влажности в помещении: чем жарче и суше, тем выше эффективность устройства Evapolar. Также важна вентиляция: в замкнутом помещении работающее устройство постепенно повысит влажность, и разница температур станет менее заметной.',
            ],
          },
          {
            title:
              'Могу ли я добавить лёд в устройство? А ароматические масла?',
            content: [
              'Добавление льда на эффективность охлаждения не повлияет, так же как и использование холодной воды из холодильника: охлаждение происходит за счёт испарения, а не разбрызгивания воды (как в более бюджетных аналогах), а прохлада исходящего воздушного потока зависит только от влажности и температуры в помещении.',
              '\n',
              '\n',
              ' Добавление ароматических масел быстро приведёт материал картриджа в негодность: нано-поры забьются, что сделает испарение воды с поверхности фильтра невозможным.',
            ],
          },
        ]}
      />
    </Wrapper>
  );
}
